import React, { Children } from 'react';
import styled, { css } from 'styled-components';
import { phone } from '../../utils/media';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.items > 1 ? 'space-between' : 'flex-end')};
  align-items: flex-end;
  gap: 15px;
  flex-wrap: wrap;
  flex-grow: 0;
  margin-top: auto;
  align-items: center;
  text-align: center;

  * {
    flex-grow: ${(props) => (props.items > 1 ? '1' : '0')};
  }

  ${phone(css`
    gap: 0;
  `)}
`;

const SolutionCardActions = ({ children }) => {
  return <ActionsWrapper items={Children.count(children)}>{children}</ActionsWrapper>;
};

export default SolutionCardActions;
