import React from 'react';
import * as PropTypes from 'prop-types';
import Text from '../../atoms/Text';
import styled, { css } from 'styled-components';
import { phone, mobile } from '../../utils/media';

const AdvantageCardWrapper = styled.div`
  display: flex;
  flex-direction: ${({ inRow }) => {
    return inRow ? 'row-reverse' : 'column';
  }};
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  ${phone(css`
    align-items: flex-start;
  `)}
`;

const AdvantageCardImage = styled.div`
  display: block;
  width: 100%;
  height: ${({ big }) => (big ? '160px' : '120px')};
  max-width: ${({ big }) => (big ? '160px' : '120px')};
  margin: 0 auto;
  background-image: url(${({ image }) => require('../../assets/images/desktop/' + image)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;
  ${mobile(css`
    width: 120px;
    height: 120px;
  `)}
`;

const AdvantageCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AdvantageCardTitle = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  text-align: ${({ inRow }) => (inRow ? 'start' : 'center')};
`;

const AdvantageCardText = styled(Text)`
  font-size: 22px;
  text-align: ${({ inRow }) => (inRow ? 'start' : 'center')};
`;

const AdvantageCard = ({ title, text, icon, inRow, bigIcon }) => {
  return (
    <AdvantageCardWrapper inRow={inRow}>
      <AdvantageCardContentWrapper>
        <AdvantageCardTitle inRow={inRow}>{title}</AdvantageCardTitle>
        <AdvantageCardText inRow={inRow}>{text}</AdvantageCardText>
      </AdvantageCardContentWrapper>
      <AdvantageCardImage image={icon} inRow={inRow} big={bigIcon} />
    </AdvantageCardWrapper>
  );
};

AdvantageCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  inRow: PropTypes.bool,
};

export default AdvantageCard;
