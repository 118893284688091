import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { phone } from '../utils/media';

const SpacerItem = styled.div`
  height: ${({ height }) => height || ''};
  width: ${({ width }) => width || ''};
  display: ${({ width }) => (!!width ? 'inline-block' : 'block')};

  ${phone(css`
    ${({ width }) => Math.floor(width / 3) || ''};
  `)}
`;

const Spacer = ({ height, width }) => <SpacerItem height={height} width={width} />;

Spacer.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

Spacer.defaultProps = {};

export default Spacer;
