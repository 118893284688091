import React from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { mobile } from '../../utils/media';
import { COLORS } from '../../assets/styles/variables';

const CardWrapper = styled.div`
  background: ${COLORS.cardBg};
  padding: 25px 35px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${mobile(css`
    padding: 15px;
  `)}
`;

const SolutionCard = ({ children }) => {
  return <CardWrapper>{children}</CardWrapper>;
};

SolutionCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SolutionCard;
