import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../assets/styles/variables';
import Text from '../../atoms/Text';
import { mobile } from '../../utils/media';

const DescriptionText = styled(Text)`
  ${mobile(css`
    font-size: 24px;
    font-weight: 400;
    word-spacing: 4px;
    line-height: 1;
  `)}
`;

const CTABannerSubText = ({ children, ...props }) => (
  <DescriptionText
    size={props.size ?? '28px'}
    color={props.color ?? COLORS.txtLight}
    weight={props.weight ?? 'bold'}
    transform={props.transform ?? 'uppercase'}
    block
    {...props}
  >
    {children}
  </DescriptionText>
);

CTABannerSubText.propTypes = {
  children: PropTypes.any.isRequired,
  block: PropTypes.bool,
};

CTABannerSubText.defaultProps = {
  block: false,
};

export default CTABannerSubText;
