import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../assets/styles/variables';
import { mobile } from '../../utils/media';

const ContentWrapper = styled('div')`
  background: ${COLORS.shadow + 'D1'};
  padding: 35px 50px;
  max-width: 650px;
  width: 100%;
  text-align: start;
  ${mobile(css`
    padding: 25px 20px;
    width: 90%;
    margin: 0 auto;
  `)}
`;

const CTABannerContentWithBg = ({ children, ...props }) => <ContentWrapper {...props}>{children}</ContentWrapper>;

CTABannerContentWithBg.propTypes = {
  children: PropTypes.any.isRequired,
};

CTABannerContentWithBg.defaultProps = {};

export default CTABannerContentWithBg;
