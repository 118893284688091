import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from '../../assets/styles/variables';
import Button from '../../atoms/Button';
import { URLS } from '../../config/constants';
import SectionHeader from '../SectionHeader/SectionHeader';
import SectionHeaderMainText from '../SectionHeader/SectionHeaderMainText';

const BecomePartnerSectionWrapper = styled.div`
  padding: 20px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLORS.txtDark};
`;

const BecomePartnerSection = ({ t }) => {
  const onBecomeDeveloperPartnerClick = () => window.open(URLS.developerPortal, '_BLANK');

  return (
    <BecomePartnerSectionWrapper>
      <SectionHeader>
        <SectionHeaderMainText>{t('app:become_partner')}</SectionHeaderMainText>
      </SectionHeader>
      <Button bgColor={COLORS.agricultureGreen} onClick={onBecomeDeveloperPartnerClick}>
        {t('app:visit_portal')}
      </Button>
    </BecomePartnerSectionWrapper>
  );
};

export default withTranslation()(BecomePartnerSection);
