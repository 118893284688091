import React from 'react';
import styled, { css } from 'styled-components';
import { mobile, phone } from '../../utils/media';
import { COLORS } from '../../assets/styles/variables';

const CardImage = styled.div`
  display: block;
  aspect-ratio: 16 / 9;
  width: 85%;
  margin: 0 auto;
  background-image: url(${({ image }) => require('../../assets/images/desktop/' + image)});
  background-size: cover;
  box-shadow: ${({ withBorderImage }) => (withBorderImage ? '0 2px 6px' + COLORS.shadowLight : 'none')};
  background-repeat: no-repeat;
  border: ${({ withBorderImage }) => (withBorderImage ? '1px solid ' + COLORS.border : 'none')};
  border-radius: 5px;

  ${mobile(css`
    width: 100%;
    background-image: url(${({ image }) => require('../../assets/images/tablet/' + image)});
  `)};

  ${phone(css`
    background-image: url(${({ image }) => require('../../assets/images/phone/' + image)});
  `)};
`;

const SolutionCardImage = ({ withBorderImage = false, image }) => {
  return <CardImage withBorderImage={withBorderImage} image={image} />;
};

export default SolutionCardImage;
